import { createSlice } from "@reduxjs/toolkit";
import {  getsportlistAction, listfeedsAction } from "../Action/AdminstratorAction";

// Define an initial state
const initialState = {
    Adminlist: null,
    loading: 'idle',
    error: null,
    sportlist:[],
    feetlistdata:[],
  
  };



const AdministratorSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      // You can include synchronous actions here if needed
    },
    extraReducers: (builder) => {
      builder
      .addCase(getsportlistAction.pending, (state) => {
        state.loading = 'pending';
      })
       .addCase(getsportlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.sportlist = action.payload;
      })
      .addCase(listfeedsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.feetlistdata = action.payload;
      })
      

      
      
              //blogs section
    }
  });
  
  export default AdministratorSlice.reducer;