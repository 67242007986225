import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Form, Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { updatepasswordAction } from "../Redux/Action/Authaction";
export default function ChangePassword() {
  const { id } = useParams();
  var idvalue = Decryptedid(atob(id));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
     
      password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Password is required') ,
    confirmpassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {

      values.id=idvalue;


      dispatch(updatepasswordAction(values));
    },
  });



  return (
    <div>
      <section>
        <Container fluid>
          <Row>
            <Col xl={4} lg={5} md={6} className="white-sec login">
              <div>
                <Row className="second-sec">
                  <Col lg={12}>
                    <h2 className="cmn-heading mb-4">Change password</h2>
                  </Col>
                  <Col lg={12}>
                    <p className="sub-heading">
                      Input your new desired password in the input fields below
                      to create a new password. We strongly advise you to store
                      it safely.
                    </p>
                  </Col>
                </Row>
                <hr className="cmn-border" />
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <div class="form-group">
                        <div className="d-flex justify-content-between">
                          <label>Password</label>
                          <span>
                            <img
                              src={require("../assets/images/info.svg").default}
                            />
                          </span>
                        </div>
                        <input
                          type="password"
                          class="form-control"
                          placeholder=""
                          onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="password"
                            value={formik.values.password}
                          />
                          {formik.submitCount > 0 && formik.errors.password && (
                            <div className="errors">{formik.errors.password}</div>
                          )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div class="form-group mt-4">
                        <div className="d-flex justify-content-between">
                          <label>Confirm new password</label>
                          <span>
                            <img
                              src={require("../assets/images/info.svg").default}
                            />
                          </span>
                        </div>
                        <input
                          type="password"
                          class="form-control"
                          placeholder=""
                          onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="confirmpassword"
                            value={formik.values.confirmpassword}
                          />
                          {formik.submitCount > 0 && formik.errors.confirmpassword && (
                            <div className="errors">{formik.errors.confirmpassword}</div>
                          )}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <hr className="cmn-border" />
                    </Col>

                    <Col lg={12}>
                      <button type="submit" class="cmn-btn w-100">
                        Change password
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
              <div>
                <Col lg={12}>
                  <div className="last-sec">
                    <p className="sub-heading">Do you need help? </p>
                    <button className="remember ">
                      <Link to="#">Customer support</Link>
                    </button>
                  </div>
                </Col>
              </div>
            </Col>
            <Col xl={8} lg={7} md={6} className="cmn-background">
              <div className="logo-center">
                <img
                  src={require("../assets/images/cluberly-white.svg").default}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
