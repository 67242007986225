import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Form, Link, NavLink, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { sendverificationemailAction } from "../Redux/Action/Authaction";
import { toast } from "react-toastify";
export default function SuccessRegisteration() {
  const [timestamp, setTimestamp] = useState(null);
  const [emailContent, setEmailContent] = useState("");

  const [userdetails, setUserdetails] = useState("");
  const [isSending, setIsSending] = useState(false);

  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {}, [id]);

  const sendMail = () => {
    // Ensure the user can't send multiple emails within 30 seconds
    if (!isSending) {
      // Simulating sending mail (you can replace this with actual logic)
      // Here, we set the timestamp to the current time
      const currentTime = new Date().toLocaleString();
      setTimestamp(currentTime);

      // Logic to actually send email
      // This is where you would send an HTTP request, etc.
      // For demonstration, we're just logging the email content and timestamp
      console.log("Email Content:", emailContent);
      console.log("Timestamp:", currentTime);

      // Clear email content after sending
      setEmailContent("");

      // Set isSending to true to prevent multiple sends
      setIsSending(true);

      // Dispatch action to send verification email
      dispatch(sendverificationemailAction({ id: id }))
        .then(function (payload) {
          // On successful dispatch, show success message
          // toast.success(
          //   "We have sent an activation email to the email you provided during registration"
          // );
        })
        .catch(function (error) {
          // Handle error if dispatch fails
          console.error("Error sending verification email:", error);
        })
        .finally(function () {
          // Reset isSending to false after 30 seconds
          setTimeout(() => {
            setIsSending(false);
          }, 30000); // 30 seconds
        });

      // Start the countdown timer
      setCountdown(30);
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(interval);
            setIsSending(false); // Clear interval when countdown reaches 0
            return 0;
          }
        });
      }, 1000);

      // Clear interval when component unmounts or countdown reaches 0
      return () => clearInterval(interval);
    }
  };

  // useEffect(() => {
  //   // Update countdown timer if isSending is true
  //   if (isSending && countdown > 0) {
  //     const interval = setInterval(() => {
  //       setCountdown((prevCountdown) => prevCountdown - 1);
  //     }, 1000);

  //     // Clear interval when component unmounts or countdown reaches 0
  //     return () => clearInterval(interval);
  //   }
  // }, [isSending, countdown]);

  useEffect(() => {
    sendMail();
  }, [userdetails]);

  return (
    <div>
      <section className="cmn-background registeration">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={7} lg={8} md={9} className="white-sec">
              <Row>
                <Col lg={12} className="d-flex justify-content-center">
                  <div className="logo">
                    <img
                      src={require("../assets/images/Logo.png")}
                      className="img-fluid"
                    />
                  </div>
                </Col>
              </Row>

              <Row className="second-sec success">
                <div>
                  <Col lg={12}>
                    <h2 className="cmn-heading text-center">
                      Application Submitted!
                    </h2>
                  </Col>
                  <Col lg={12}>
                    <p className="sub-heading text-center">
                      Thank you for submitting your application to become a
                      Cluberly Player Sponsor.
                      <br />
                      Our team will review your application within the next 30
                      days.
                    </p>
                    <div className="loader">
                      {isSending && <p>{countdown}s</p>}
                    </div>
                    <p className="sub-heading mt-4 text-center">
                      If your application is successful, you'll receive an email
                      containing details of how to sign in.
                      <br />
                      If you have any questions about your application, you can
                      contact us on{" "}
                      <a href="mailto:registrationsupport@cluberly.com;">
                        registrationsupport@cluberly.com
                      </a>
                    </p>
                  </Col>
                  <Col lg={12} className="text-center">
                    <button onClick={sendMail} disabled={isSending}>
                      Re-send the verification email{" "}
                    </button>
                  </Col>
                  <Col lg={12} className="d-flex justify-content-center">
                    <button
                      type="submit"
                      class="cmn-btn"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Close
                    </button>
                  </Col>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
