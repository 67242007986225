
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../ApiConfig";




export const getsportlistAction =createAsyncThunk('addsportsandposition',async(obj)=>{

    const  {data}  = await Api.get(`/playerportal/addsportsandposition`,obj);

      if(data.status==201){
         
         
           return data
      }

})


export const playerpicAction =createAsyncThunk('playerpic',async(obj)=>{

  const  {data}  = await Api.post(`/playerportal/playerpic`,obj);

    if(data.status==201){
        console.log(data.image,"data.sportlistdatadata.sportlistdata")
       
         return data.image
    }

})


export const playerdetailsAction =createAsyncThunk('playerdetails',async(obj)=>{

  const  {data}  = await Api.post(`/playerportal/playerdetails`,obj);

    if(data.status==200){
      
       
         return data.getplayerdetails
    }

})



export const addandupdatefeedsinformationAction =createAsyncThunk('addandupdatefeedsinformation',async(obj)=>{

  const  {data}  = await Api.post(`/playerportal/addandupdatefeedsinformation`,obj);

    if(data.status==200){
      
       
         return data.saveinformation
    }

})



export const listfeedsAction =createAsyncThunk('listfeeds',async(obj)=>{

  const  {data}  = await Api.post(`/playerportal/listfeeds`,obj);

    if(data.status==201){
      
       
         return data.feedlist
    }

})


export const deletefeedAction =createAsyncThunk('deletefeed',async(obj)=>{

  const  {data}  = await Api.post(`/playerportal/deletefeed`,obj);

    if(data.status==201){
      
       
         return obj
    }

})



export const detailfeedsAction =createAsyncThunk('detailfeeds',async(obj)=>{

  const  {data}  = await Api.post(`/playerportal/detailfeeds`,obj);

    if(data.status==201){
      
       
         return data.feeddetails
    }

})










 
 

 




    


    
    