import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../Components/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";

export default function AcountSummary() {
  return (
    <Layout>
      <div className="top-bar d-flex mb-4">
        <img src={require("../assets/images/file.svg").default} />
        <h2>Feed</h2>
      </div>

      <Container fluid>
        <Row>
          <Col xxl={3} xl={4} lg={4}>
            <div className="top-boxes">
              <h2>DONATIONS TOTAL</h2>
              <p>£22,000.00</p>
            </div>
          </Col>
          <Col xxl={3} xl={4} lg={4}>
            <div className="top-boxes">
              <h2>PAID OUT TOTAL</h2>
              <p>£20,000.00</p>
            </div>
          </Col>
          <Col xxl={6} xl={4} lg={4} className="d-flex justify-content-xxl-end">
            <div className="top-boxes">
              <h2>PENDING DONATION PAYOUT</h2>
              <p>£2,000.00</p>
            </div>
          </Col>
        </Row>
        <table class="table" bordered>
          <thead>
            <tr>
              <th scope="col">S. No</th>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Transaction Description</th>
              <th scope="col">Transaction Type</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>{" "}
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>{" "}
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>{" "}
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>{" "}
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>{" "}
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>{" "}
            <tr>
              <td>#0001</td>
              <td>01/02/2024</td>
              <td>John Cena</td>
              <td>Lorem Ipsum is dummy text.</td>
              <td>Paidout</td>
              <td>£100,000.00</td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  );
}
