import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../Components/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import {
  addandupdatefeedsinformationAction,
  detailfeedsAction,
  playerpicAction,
} from "../Redux/Action/AdminstratorAction";
import { toast } from "react-toastify";
import { Decryptedid } from "../Util/BcruptEncyptid";

export default function EditFeed() {
  const [file, setFile] = useState();
  const [fileurl, setFileurl] = useState("");
  const [filetypestatus, setFiletypestatus] = useState("");
const {id}=useParams();

const devid=Decryptedid(atob(id))

useEffect(()=>{
  dispatch(detailfeedsAction({
    id:devid
  })).then(function (data) {
    if (data.payload) {
      var Details = data.payload;
      for (const [key, value] of Object.entries(Details)) {
        if (key != "_id" && key != "image" && key !="type_file") {
          formik.setFieldValue(key, value, { strict: false });
        }
        if (key == "type_file") {
          setFiletypestatus(value)
          formik.setFieldValue(key, value, { strict: false });
          
        }

        if (key == "image") {
          setFileurl(value)
          formik.setFieldValue(key, value, { strict: false });
         
        }


      }
    }
  });
},[devid])

  function checkFileType(url) {
    // Extracting the file extension from the URL
    var extension = url.split(".").pop().toLowerCase();

    // Checking if the file extension corresponds to a video or image
    if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov" ||
      extension === "wmv" ||
      extension === "flv"
    ) {
      return "1";
    } else if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif" ||
      extension === "bmp" ||
      extension === "webp"
    ) {
      return "0";
    }
  }

  function handleChange(e) {
    let fordata = new FormData();

    fordata.append("player", e.target.files[0]);
    dispatch(playerpicAction(fordata)).then(function (data) {
      setFileurl(process.env.REACT_APP_IMAGE_URL + data.payload);

      setFiletypestatus(
        checkFileType(process.env.REACT_APP_IMAGE_URL + data.payload)
      );
    });

    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const navigate = useNavigate();

  const dispatch = useDispatch();
  // addandupdatefeedsinformationAction

  const formik = useFormik({
    initialValues: {
      activity: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      activity: Yup.string().required("Activity is required"),
      description: Yup.string().required("Description is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (fileurl != "") {
        var fileType = checkFileType(fileurl);
      }
     
      values.image = fileurl;
      values.id = devid;
      values.type_file = fileType;
      dispatch(addandupdatefeedsinformationAction(values)).then(function (
        data
      ) {
        navigate("/feeds");
      });
    },
  });

  console.log(filetypestatus,"filetypestatusfiletypestatus")
  return (
    <Layout>
      <form onSubmit={formik.handleSubmit}>
        <div className="top-bar d-flex mb-4 align-items-center">
          <div>
            {" "}
            <Link to="/feeds" className="back-btn">
              <img
                src={require("../assets/images/Arrow_alt_lright.svg").default}
              />
            </Link>
          </div>
          <img src={require("../assets/images/file.svg").default} />
          <h2>Feed</h2>
        </div>
        <Container>
          <div>
            <Row>
              <Col lg={12}>
                <h2 className="heading-main">Edit Feed</h2>
              </Col>
              <Col lg={12}>
                <div class="form-group mb-4">
                  <div className="d-flex justify-content-between">
                    <label>Activity</label>
                  </div>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="activity"
                    value={formik.values.activity}
                  >
                    <option>Select activity</option>

                    <option value="training">Training</option>
                    <option value="fundraising">Fundraising</option>
                    <option value="game">Game</option>
                    <option value="championship">Championship</option>
                  </select>

                  {formik.submitCount > 0 && formik.errors.activity && (
                    <div className="errors">{formik.errors.activity}</div>
                  )}
                </div>
              </Col>
              <Col lg={12}>
                {" "}
                <div class="form-group mb-4">
                  <div className="d-flex justify-content-left">
                    <label>Upload Image(s)/Video</label>
                  </div>
                  <div className="img-upload register">
                    <div className="image-placeholder text-center">
                      <img
                        src={
                          require("../assets/images/imageupload.svg").default
                        }
                      />
                      <p>Click to upload</p>
                    </div>
                    <input type="file" onChange={handleChange} />
                    {
                       
                      filetypestatus==0? <img
                              src={fileurl == "" ? file : fileurl}
                              crossOrigin="anonymous"
                              alt="no image"
                            />: <video
                              src={fileurl == "" ? file : fileurl}
                              crossOrigin="anonymous"
                              alt="no image"
                            />
                    }
                      
                      
                     
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div class="form-group mb-4">
                  <div className="d-flex justify-content-between">
                    <label>Add Desciption</label>
                  </div>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Enter your description here..."
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="description"
                    value={formik.values.description}
                  />
                  {formik.submitCount > 0 && formik.errors.description && (
                    <div className="errors">{formik.errors.description}</div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="cmn-color-btn">
          <button type="submit">Save</button>
        </div>
      </form>
    </Layout>
  );
}
