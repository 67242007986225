import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addandupdateplayerAction } from "../Redux/Action/Authaction";
import { Decryptedid } from "../Util/BcruptEncyptid";

import moment from "moment/moment";
import {
  playerdetailsAction,
  playerpicAction,
} from "../Redux/Action/AdminstratorAction";
export default function Registeration({
  formData,
  setFormData,
  nextStep,
  clubdeatils,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [fileurl, setFilefileurl] = useState("");

  useEffect(() => {
    dispatch(playerdetailsAction({ id: id })).then(function (
      playerinformation
    ) {
      if (playerinformation?.payload) {
        var Details = playerinformation?.payload;
        for (const [key, value] of Object.entries(Details)) {
          if (key == "age") {
            if (value == undefined) {
              formik.setFieldValue(key, moment(value).format("yyyy-MM-DD"), {
                strict: false,
              });
            }
          } else if (key == "professional") {
            setFilefileurl(value);
          } else {
            formik.setFieldValue(key, value, { strict: false });
          }
        }
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      surname: "",
      age: "",
      experience: "",
      email: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
      firstname: Yup.string().required("First name is required"),
      surname: Yup.string().required("Sur Name is required"),
      age: Yup.string().required("Age is required"),
      experience: Yup.string().required("Experience is required"),
      termcondition: Yup.string().required("Terms and conditions is required"),
      password: Yup.string()
        .required("Password is required")
        .min(5, "Your password is too short.")
        .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
      confirmpassword: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords must match"
      ),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.id = id;

      values.firstname=values.firstname[0].toUpperCase() + values.firstname.slice(1);
      values.surname=values.surname[0].toUpperCase() + values.surname.slice(1);

      values.professional = fileurl;
      values.invitedstatus = 1;
      dispatch(addandupdateplayerAction(values)).then(function (data) {
        if (data?.payload?._id != undefined) {
          navigate("/" + data?.payload?._id);
          nextStep();
        }
      });
    },
  });

  const handleChange = useCallback((e) => {
    let fordata = new FormData();

    fordata.append("player", e.target.files[0]);
    dispatch(playerpicAction(fordata)).then(function (data) {
      setFilefileurl(process.env.REACT_APP_IMAGE_URL + data.payload);
    });

    setFile(URL.createObjectURL(e.target.files[0]));
  });
  return (
    <div>
      <section>
        <Container fluid>
          <Row>
            <Col xl={7} lg={8} md={9} className="white-sec">
              <Row>
                <Col lg={6}>
                  <div className="logo">
                    <img
                      src={require("../assets/images/Logo.png")}
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="profile-sec d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Already a Cluberly member?{" "}
                      <img
                        src={require("../assets/images/profile.svg").default}
                      />
                    </button>
                  </div>
                </Col>
              </Row>

              <Row className="second-sec">
                <Col lg={6}>
                  <h2 className="cmn-heading">Input your information</h2>
                </Col>
                <Col lg={6}>
                  <p className="sub-heading">
                    Welcome to Cluberly!
                    <br /> We’re glad you’ve made it here. Register your <br />
                    company details here to create an account. <br />
                    Here are our <NavLink to="">terms and conditions. </NavLink>
                  </p>
                </Col>
              </Row>
              <hr className="cmn-border" />
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <div class="form-group mb-4 ">
                      <div className="d-flex justify-content-between">
                        <label>Upload Professional Image</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <div className="img-upload register">
                        <div className="image-placeholder text-center">
                          <img
                            src={
                              require("../assets/images/imageupload.svg")
                                .default
                            }
                          />
                          {
                            fileurl =="" &&  <p>Click to Upload Photo</p>
                          }
                         
                        </div>
                        <input type="file" onChange={handleChange} />
                        <img
                          src={fileurl != "" ? fileurl : file}
                          crossOrigin="anonymous"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div className="register-left">
                      <p>
                        Upload your Professional Image. This will be displayed
                        on your profile page and in search listings.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>First name</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="firstname"
                        value={formik.values.firstname}
                      />
                      {formik.submitCount > 0 && formik.errors.firstname && (
                        <div className="errors">{formik.errors.firstname}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label for="exampleInputPassword1">Surname</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Surname"
                        class="form-control"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="surname"
                        value={formik.values.surname}
                      />
                      {formik.submitCount > 0 && formik.errors.surname && (
                        <div className="errors">{formik.errors.surname}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Age</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="date"
                        class="form-control"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="age"
                        value={formik.values.age}
                      />
                      {formik.submitCount > 0 && formik.errors.age && (
                        <div className="errors">{formik.errors.age}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Select Experience Level</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>

                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="experience"
                        value={formik.values.experience}
                      >
                        <option>Please select the Experience</option>

                        <option value="amateur">Amateur</option>
                        <option value="rising talent">Rising Talent</option>
                        <option value="professional">Professional</option>
                      </select>

                      {formik.submitCount > 0 && formik.errors.experience && (
                        <div className="errors">{formik.errors.experience}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Email</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter Email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="email"
                        value={formik.values.email}
                      />
                      {formik.submitCount > 0 && formik.errors.email && (
                        <div className="errors">{formik.errors.email}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    {/* <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Phone number</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <div className="phone-select d-flex">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>+44</option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="mobile"
                          value={formik.values.mobile}
                        />
                      </div>
                      {formik.submitCount > 0 && formik.errors.mobile && (
                        <div className="errors">{formik.errors.mobile}</div>
                      )}
                    </div> */}
                  </Col>
                  <Col lg={12}>
                    <hr className="cmn-border" />
                  </Col>
                  <Col lg={6}>
                    <div class="form-group">
                      <div className="d-flex justify-content-between">
                        <label>Password</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="password"
                        value={formik.values.password}
                      />
                      {formik.submitCount > 0 && formik.errors.password && (
                        <div className="errors">{formik.errors.password}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group">
                      <div className="d-flex justify-content-between">
                        <label>Confirm password</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Confirm password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="confirmpassword"
                        value={formik.values.confirmpassword}
                      />
                      {formik.submitCount > 0 &&
                        formik.errors.confirmpassword && (
                          <div className="errors">
                            {formik.errors.confirmpassword}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <hr className="cmn-border" />
                  </Col>

                  <Col lg={6}>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="termcondition"
                        value={formik.values.termcondition}
                      />
                      <label
                        class="form-check-label sub-heading"
                        for="exampleCheck1"
                      >
                        I agree with <Link to="#">terms and conditions.</Link>
                      </label>
                      {formik.submitCount > 0 &&
                        formik.errors.termcondition && (
                          <div className="errors">
                            {formik.errors.termcondition}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <button type="submit" class="cmn-btn">
                      Next
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col xl={5} lg={4} md={3} className="cmn-background"></Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
