import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../Components/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { addandupdateplayerAction } from "../Redux/Action/Authaction";
import {
  deletefeedAction,
  listfeedsAction,
} from "../Redux/Action/AdminstratorAction";
import { Encryptedid } from "../Util/BcruptEncyptid";
export default function Feeds() {
  const [show, setShow] = useState(false);
  const [eventid, setEventid] = useState("");
  const handleClose = () => {
    setShow(false);

    setEventid("");
  };
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listfeedsAction());
  }, []);
  const feetlistdata = useSelector((state) => state.Administrator.feetlistdata);

  return (
    <Layout>
      <div className="top-bar d-flex mb-4">
        <img src={require("../assets/images/file.svg").default} />
        <h2>Feed</h2>
      </div>
      <Container fluid>
        <Row className="feeds-set">
          <Col lg={8}>
            <div className="feeds-main">
              {feetlistdata?.map(function (object, i) {
                return (
                  <div className="feed-box">
                    <div className="top-desc">
                      <div className="d-flex">
                        <img
                          className="user-img"
                          src={object?.Player_id?.professional}
                          crossOrigin="anonymous"
                        />
                        <div className="description">
                          <h2>
                            {object?.Player_id?.firstname +
                              " " +
                              object?.Player_id?.surname}
                          </h2>
                          <p>{object?.description}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <Link to={"/edit-feed/" + Encryptedid(object?._id)}>
                          <img
                            src={
                              require("../assets/images/edit_icon.svg").default
                            }
                          />
                        </Link>
                        <button
                          type="button"
                          // onClick={() => {
                          //   dispatch(
                          //     deletefeedAction({
                          //       id: object?._id,
                          //     })
                          //   );

                          // }}
                          onClick={() => {
                            setShow(true);
                            setEventid(object?._id);
                          }}
                        >
                          <img
                            src={
                              require("../assets/images/deleteicon.svg").default
                            }
                          />
                        </button>
                      </div>
                    </div>
                    {object?.image != "" && (
                      <div className="feed-img">
                        {object?.type_file == 0 ? (
                          <img src={object?.image} crossOrigin="anonymous" />
                        ) : (
                          <>
                            <video width="100%" height="100%" controls>
                              <source
                                src={object?.image}
                                crossOrigin="anonymous"
                                type="video/mp4"
                              />
                            </video>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Col>
          <Col lg={4}>
            <div className="feeds-right">
              <div class="form-group">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.0756 9.9018C11.9555 10.7811 12.8248 11.6523 13.6971 12.521C13.9338 12.7567 14.0577 13.0252 13.9737 13.3619C13.823 13.9687 13.1151 14.2018 12.6362 13.8003C12.5173 13.7007 12.4122 13.5849 12.3019 13.4752C11.513 12.6879 10.7247 11.8996 9.93628 11.1123C9.92718 11.1032 9.91555 11.0971 9.89229 11.0805C8.22148 12.2763 6.38531 12.6424 4.39591 12.066C2.95267 11.6478 1.8184 10.7831 0.999683 9.52611C-0.660505 6.97818 -0.21145 3.59594 2.03888 1.5855C4.33119 -0.462854 7.72287 -0.532633 10.092 1.41207C12.5285 3.4134 13.1555 7.14959 11.0756 9.9018ZM6.16887 10.6653C8.66143 10.6618 10.6625 8.66096 10.665 6.16966C10.6675 3.67077 8.6584 1.66186 6.15876 1.66438C3.66721 1.66691 1.66568 3.66824 1.66315 6.16056C1.66062 8.65995 3.66974 10.6684 6.16887 10.6653Z"
                    fill="#D1D1D1"
                  />
                </svg>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search here..."
                />
              </div>
              <Link to="/create-feed" className="next-btn">
                Create Feed
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} className="delete-popup">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={require("../assets/images/delete.svg").default} />
          <p> Are you sure you want to delete this item?</p>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(
                deletefeedAction({
                  id: eventid,
                })
              ).then(function () {
                setShow(false);
                dispatch(listfeedsAction());
              });
            }}
          >
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
