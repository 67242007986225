import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, Link, NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  checkotpAction,
  forgotpasswordAction,
} from "../Redux/Action/Authaction";
export default function OtpCheck() {
  //forgotpasswordAction

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      resetpasswordotp: "",
    },
    validationSchema: Yup.object().shape({
      resetpasswordotp: Yup.number().required("Otp is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(checkotpAction(values));
    },
  });

  return (
    <div>
      <section>
        <Container fluid>
          <Row>
            <Col xl={4} lg={5} md={6} className="white-sec login">
              <div>
                <Row className="second-sec">
                  <Col lg={12}>
                    <h2 className="cmn-heading mb-4">OTP</h2>
                  </Col>
                  <Col lg={12}>
                    <p className="sub-heading">
                      We've sent a code to the email address you have provided.
                      Please enter the code below to proceed to updating your
                      password. If you have not received your code within 5
                      minutes, please check your spam or request another code
                    </p>
                  </Col>
                </Row>
                <hr className="cmn-border" />
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <div class="form-group">
                        <div className="d-flex justify-content-between">
                          <label>Otp</label>
                        </div>

                        <input
                          type="text"
                          class="form-control"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="resetpasswordotp"
                          value={formik.values.resetpasswordotp}
                        />
                        {formik.submitCount > 0 &&
                          formik.errors.resetpasswordotp && (
                            <div className="errors">
                              {formik.errors.resetpasswordotp}
                            </div>
                          )}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <hr className="cmn-border" />
                    </Col>

                    <Col lg={12}>
                      <button type="submit" class="cmn-btn w-100">
                        Request password change
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
              <div>
                <Col lg={12}>
                  <div className="last-sec">
                    <p className="sub-heading">Do you need help?</p>
                    <button className="remember ">
                      <Link to="#">Customer support</Link>
                    </button>
                  </div>
                </Col>
              </div>
            </Col>
            <Col xl={8} lg={7} md={6} className="cmn-background">
              <div className="logo-center">
                <img
                  src={require("../assets/images/cluberly-white.svg").default}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
