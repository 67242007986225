import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Registeration from "./Pages/Registeration";
import SuccessRegisteration from "./Pages/SuccessRegisteration";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ChangePassword from "./Pages/ChangePassword";
import Dashboard from "./Pages/Dashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BuisnessSelect from "./Pages/BuisnessSelect";
import ClubSelect from "./Pages/ClubSelect";
import CharitySelect from "./Pages/CharitySelect";
import SchoolSelect from "./Pages/SchoolSelect";
import Profile from "./Pages/Profile";
import EditProfile from "./Pages/EditProfile";

import ClubLogo from "./Pages/ClubLogo";
import MasterRegistationfile from "./Pages/MasterRegistationfile";
import RegisterationTwo from "./Pages/RegisterationTwo";
import SportsDetail from "./Pages/SportsDetail";
import CoverPhoto from "./Pages/CoverPhoto";
import SocialMedia from "./Pages/SocialMedia";
import Feeds from "./Pages/feeds";
import CreateFeed from "./Pages/CreateFeed";
import AcountSummary from "./Pages/AccountSummary";
import Masterplayerregistration from "./Pages/Masterplayerregistration";
import EditFeed from "./Pages/EditFeed";
import OtpCheck from "./Pages/OtpCheck";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/:id" element={<Masterplayerregistration />} />
          <Route
            path="/successregisteration/:id"
            element={<SuccessRegisteration />}
          />
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/changepassword/:id" element={<ChangePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/buisnesselect/:id"
            element={<MasterRegistationfile />}
          />
   <Route
            path="/ResetOtp/:id"
            element={<OtpCheck />}
          />



          <Route path="/clubselect/:id" element={<ClubSelect />} />
          <Route path="/charityselect" element={<CharitySelect />} />
          <Route path="/schoolselect" element={<SchoolSelect />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/cover-photo" element={<CoverPhoto />} />
          <Route path="/clublogo" element={<ClubLogo />} />




          <Route path="/registeration-two" element={<RegisterationTwo />} />
          <Route path="/sports-detail" element={<SportsDetail />} />
          <Route path="/social-media" element={<SocialMedia />} />
          <Route path="/feeds" element={<Feeds />} />
          <Route path="/create-feed" element={<CreateFeed />} />
          <Route path="/account-summary" element={<AcountSummary />} />
          <Route path="/edit-feed/:id" element={<EditFeed />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
