


import React, { useEffect, useState } from 'react'


import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Registeration from './Registeration';
import RegisterationTwo from './RegisterationTwo';


// import { useDispatch } from 'react-redux';
export default function Masterplayerregistration() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});

    const nextStep = () => {
        setStep(step + 1);
      };

    //   const dispatch = useDispatch();
      const prevStep = () => {
        setStep(step - 1);
      };


      const navigate = useNavigate();
      const dispatch=useDispatch();
    
      const [clubdeatils,setclubdeatils]=useState({});
      const { id } = useParams();

      
     

  switch(step){
    case 1:
        return (
            <Registeration  formData={formData} setFormData={setFormData} nextStep={nextStep}  />
        )
    case 2:
        return (
            <RegisterationTwo  formData={formData} setFormData={setFormData} nextStep={nextStep}  prevStep={prevStep}    />
        )

       
    case 4:
     

default:
    return null;
  }
}