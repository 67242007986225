import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, Link, NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { forgotpasswordAction } from "../Redux/Action/Authaction";
export default function ForgotPassword() {

  //forgotpasswordAction

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),

     
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(forgotpasswordAction(values));
    },
  });


  return (
    <div>
      <section>
        <Container fluid>
          <Row>
            <Col xl={4} lg={5} md={6} className="white-sec login">
              <div>
                <Row className="second-sec">
                  <Col lg={12}>
                    <h2 className="cmn-heading mb-4">Forgot password?</h2>
                  </Col>
                  <Col lg={12}>
                    <p className="sub-heading">
                      Don’t worry we can help you out! if you still remember
                      your email address you can quickly reset your password.
                      Just input that information in the fields below and click
                      on the button. This will send you a new email that will
                      link you to the password change website.
                    </p>
                  </Col>
                </Row>
                <hr className="cmn-border" />
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <div class="form-group">
                        <div className="d-flex justify-content-between">
                          <label>Email address</label>
                        </div>

                        <input
                          type="Email"
                          class="form-control"
                          
                          onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name="email"
                            value={formik.values.email}
                          />
                          {formik.submitCount > 0 && formik.errors.email && (
                            <div className="errors">{formik.errors.email}</div>
                          )}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <hr className="cmn-border" />
                    </Col>

                    <Col lg={12}>
                    
                        <button type="submit" class="cmn-btn w-100">
                          Request password change
                        </button>
                    
                    </Col>
                  </Row>
                </form>
              </div>
              <div>
                <Col lg={12}>
                  <div className="last-sec">
                    <p className="sub-heading">Do you need help?</p>
                    <button className="remember ">
                      <Link to="#">Customer support</Link>
                    </button>
                  </div>
                </Col>
              </div>
            </Col>
            <Col xl={8} lg={7} md={6} className="cmn-background">
              <div className="logo-center">
                <img
                  src={require("../assets/images/cluberly-white.svg").default}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
