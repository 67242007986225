import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addandupdateplayerAction } from "../Redux/Action/Authaction";
import { Decryptedid } from "../Util/BcruptEncyptid";

import moment from "moment/moment";
import {
  getsportlistAction,
  playerdetailsAction,
} from "../Redux/Action/AdminstratorAction";
export default function RegisterationTwo() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getsportlistAction());
  }, []);

  const sportlistdata = useSelector((state) => state.Administrator.sportlist);

  useEffect(() => {
    dispatch(playerdetailsAction({ id: id })).then(function (
      playerinformation
    ) {
      if (playerinformation?.payload) {
        var Details = playerinformation?.payload;
        for (const [key, value] of Object.entries(Details)) {
          if (key != "password") {
            if (key == "current_date" || key == "previous_date") {
              if (value != null) {
                formik.setFieldValue(key, moment(value).format("yyyy-MM-DD"), {
                  strict: false,
                });
              }
            } else if (key == "sport_id") {
              setFilteredPositions(sportlistdata?.playerPosition);
              formik.setFieldValue(key, value, { strict: false });
            } else {
              if (value != null) {
                formik.setFieldValue(key, value, { strict: false });
              }
            }
          }
        }
      }
    });
  }, []);

  console.log(sportlistdata, "sportlistdatasportlistdata");
  const formik = useFormik({
    initialValues: {
      sport_id: "",
      position: "",
      current_team_name: "",
      current_date: "",
      previous_team_name: "",
      previous_date: "",
    },
    validationSchema: Yup.object().shape({
      sport_id: Yup.string().required("Sport is required"),
      position: Yup.string().required("Position is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.id = id;
      dispatch(addandupdateplayerAction(values)).then(function (data) {
        navigate("/successregisteration/" + data?.payload?._id);
      });
    },
  });

  const [filteredPositions, setFilteredPositions] = useState([]);

  const handleSportChange = (event) => {
    const selectedSportId = event.target.value;
    const selectedSport = sportlistdata?.sport.find(
      (sport) => sport._id === selectedSportId
    );

    // Filter player positions based on the selected sport
    const positions = selectedSport
      ? sportlistdata?.playerPosition.filter(
          (position) => position.sport === selectedSport._id
        )
      : [];
    setFilteredPositions(positions);
  };

  return (
    <div>
      <section>
        <Container fluid>
          <Row>
            <Col xl={7} lg={8} md={9} className="white-sec">
              <Row>
                <Col lg={6}>
                  <div className="logo">
                    <img
                      src={require("../assets/images/Logo.png")}
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="profile-sec d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Already a Cluberly member?{" "}
                      <img
                        src={require("../assets/images/profile.svg").default}
                      />
                    </button>
                  </div>
                </Col>
              </Row>

              <Row className="second-sec">
                <Col lg={6}>
                  <h2 className="cmn-heading">Input your information</h2>
                </Col>
                <Col lg={6}>
                  <p className="sub-heading">
                    Welcome to Cluberly!
                    <br /> We’re glad you’ve made it here. Register your <br />
                    company details here to create an account. <br />
                    Here are our <NavLink to="">terms and conditions. </NavLink>
                  </p>
                </Col>
              </Row>
              <hr className="cmn-border" />
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Select Sport</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onBlur={formik.handleBlur}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleSportChange(event);
                        }}
                        name="sport_id"
                        value={formik.values.sport_id}
                      >
                        <option>Please select your sport</option>
                        {sportlistdata?.sport?.map(function (object) {
                          return (
                            <option value={object?._id}>{object?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label for="exampleInputPassword1">
                          Type/Select Position
                        </label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>

                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="position"
                        value={formik.values.position}
                      >
                        <option>Please select your position</option>
                        {filteredPositions.map((object) => (
                          <option key={object._id} value={object._id}>
                            {object.name}
                          </option>
                        ))}
                      </select>

                      {formik.submitCount > 0 && formik.errors.position && (
                        <div className="errors">{formik.errors.position}</div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Current Team Name (Optional)</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="current_team_name"
                        value={formik.values.current_team_name}
                      />
                      {formik.submitCount > 0 &&
                        formik.errors.current_team_name && (
                          <div className="errors">
                            {formik.errors.current_team_name}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Current Team join Date (Optional)</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Enter"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="current_date"
                        value={formik.values.current_date}
                      />
                      {formik.submitCount > 0 && formik.errors.current_date && (
                        <div className="errors">
                          {formik.errors.current_date}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Previous Team Name (Optional)</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="Enter"
                        class="form-control"
                        placeholder="Enter"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="previous_team_name"
                        value={formik.values.previous_team_name}
                      />
                      {formik.submitCount > 0 &&
                        formik.errors.previous_team_name && (
                          <div className="errors">
                            {formik.errors.previous_team_name}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Previous Team join Date (Optional)</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Enter"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="previous_date"
                        value={formik.values.previous_date}
                      />
                      {formik.submitCount > 0 &&
                        formik.errors.previous_date && (
                          <div className="errors">
                            {formik.errors.previous_date}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <hr className="cmn-border" />
                  </Col>

                  <Col lg={6}>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="termcondition"
                        value={formik.values.termcondition}
                      />
                      <label
                        class="form-check-label sub-heading"
                        for="exampleCheck1"
                      >
                        I agree with <Link to="#">terms and conditions.</Link>
                      </label>
                      {formik.submitCount > 0 &&
                        formik.errors.termcondition && (
                          <div className="errors">
                            {formik.errors.termcondition}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <button type="submit" class="cmn-btn">
                      Save
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col xl={5} lg={4} md={3} className="cmn-background"></Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
