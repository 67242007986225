import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { Container } from "react-bootstrap";

const Layout = ({ children }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <>
      <section className="cmn-side">
        <button type="button" className="toggle-btn" onClick={toggleClass}>
          <img src={require("../assets/images/toggle.svg").default} />
        </button>
        <Container fluid className="px-0">
          <div className={isActive ? "hide main" : "main"}>
            <Sidebar />
            <div className="right-side">{children}</div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Layout;
